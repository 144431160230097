<template>
  <v-app>
    <v-card flat tile class="overflow-hidden h-100">
      <v-app-bar
        class="fixed-top"
        id="app-bar"
        absolute
        white
        height="70px"
      >
        <div class="d-flex align-items-center mx-auto col-12 mt-1">
          <v-col>
            <v-toolbar-title>
              <small class="font-weight-bold">Register for {{title}}</small>
            </v-toolbar-title>
          </v-col>
          <v-col class="text-center">
            <v-btn-toggle mandatory class="ml-6">
              <v-btn
                text
                small
                :disabled="pageNumber == 1"
                class="text-capitalize border-0 blue--text"
                @click="$emit('paginate', -1)"
              >Back</v-btn>
              <v-btn
                text
                small
                class="font-weight-bold text-lowercase border-0"
              >{{pageNumber}} of 3</v-btn>
              <v-btn
                text
                small
                :disabled="pageNumber == 3"
                class="text-capitalize border-0 blue--text"
                @click="$emit('paginate', 1)"
              >Next</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col class="text-right">
            <router-link to="/registrations" class="black--text">
              <v-icon small>mdi-arrow-left</v-icon>
              <small class="font-weight-bold">Back to Registrations</small>
            </router-link>
          </v-col>
        </div>
      </v-app-bar>
      <v-col cols="11" class="form-section mx-auto px-0 py-0">
        <slot></slot>
      </v-col>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "layout",
  props: {
    title: String,
    "page-number": Number
  }
};
</script>

<style scoped>
#app-bar {
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #eeebeb;
  opacity: 1;
}
a:hover {
  text-decoration: none;
}
#hero {
  background: url("~@/assets/images/svg/form-background.svg") no-repeat;
  background-size: 600px;
  background-position: right center;
}
.form-section{
  margin-top: 100px;
  margin-bottom: 70px;
}
</style>
