<template>
  <v-row justify="start" align="center">
    <v-col cols="12" md="6" class="text-left">
      <h3 class="display-1">Payment</h3>
      <p class="mb-4">pay for your chosen plan</p>
      <v-card id="price-card" class="px-6 py-6 border-success dotted" outlined>
        <v-container>
          <v-card-title class="display-1 font-weight-bold green--text darken-4"
            >12, 500</v-card-title
          >
          <v-card-subtitle class="text-muted">per anum</v-card-subtitle>
          <v-card-subtitle class="text-muted">{{ plan }} Plan</v-card-subtitle>
        </v-container>
      </v-card>
      <v-radio-group>
        <v-radio
          color="success"
          v-for="(field, index) in [
            ' with card',
            ' to bank and upload reciept',
          ]"
          :key="index"
          :value="field"
        >
          <template v-slot:label>
            <small class="pt-2">Pay {{ field }}</small>
          </template>
        </v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" md="6" class="h-100">
      <form>
        <!-- implements Paystack inline -->
        <v-row align="center" justify="center">
          <v-col cols="8">
            <!-- loading indicator -->
            <v-progress-circular
              v-if="isLoading"
              :width="3"
              color="green darken-4"
              class="d-block mx-auto"
              indeterminate
            ></v-progress-circular>
            <!-- pay button -->
            <v-btn
              v-else
              @click="register"
              color="white--text font-weight-bold green darken-4 w-100"
              >Pay</v-btn
            >
          </v-col>
          <v-col cols="8">
            <v-img src="~@/assets/images/png/paystack-ii.png" />
          </v-col>
        </v-row>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["plan", "register"],
  computed: {
    ...mapGetters({ isLoading: "enrollmentModule/loading" }),
  },
};
</script>

<style scoped>
#price-card {
  border-style: dashed;
}
</style>
