<template>
  <v-card class="h-100 py-5">
    <!-- user avatar -->
    <v-row class="mx-10 mt-2">
      <v-col class="d-inline-flex p-0">
        <v-avatar size="100" color="green darken-4" class="white--text">
          <img :src="'https://ui-avatars.com/api/?name=' + data['Full Name']" alt="Avatar" />
        </v-avatar>
        <div class="d-flex flex-column justify-content-center ml-6">
          <v-btn small color="green darken-4 white--text mb-3">Upload Picture</v-btn>
          <v-btn small color="grey darken-6" @click.prevent="takeSnapShot">Take a Picture</v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- user details -->
    <v-row justify="start" class="text-left mx-10 mt-3">
      <v-col cols="12" md="4" class="p-1" v-for="(value, name, fieldIndex) in data" :key="fieldIndex.toString()">
        <template v-if="name !== 'password' || name !== 'confirm_password'">
          <small class="font-weight-bold">{{ name }}</small>:
          <small>{{value.match(dateFormat) ? value.split('-').reverse().join('-') : value}}</small>
        </template>
      </v-col>
    </v-row>
    <v-row justify-sm="start" justify-md="end" class="mx-10 mb-2 px-0">
      <v-col class="text-left" cols="2">
        <v-btn
          @click="$emit('paginate', 1)"
          class="white--text px-9 font-weight-bold"
          medium
          color="green darken-4"
        >Proceed</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    dateFormat() {
      return /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
    }
  },
  methods: {
    checkProperties(obj) {
      // checks if all properties are assigned
      for (let key in obj) {
        if (obj[key] !== null && obj[key] != "") return false;
      }
      return true;
    },
    takeSnapShot(){
      try {
        throw Error("Unimplement function takeSnapshot")
      } catch (error) {
        alert(error.message)
      }
    }
  }
};
</script>

